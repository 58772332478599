const CONSTANTS = Object.freeze({

    NO_DATA_TEXT: "No Results",

    MISSING_INPUT_ERROR: "Range criteria is missing",
    INVALID_DATE_ERROR: "End period is before start date",
    INVALID_PERIOD_FORMAT_ERROR: "Period format is invalid, please use MMM-YY (e.g. JAN-20)",
    ALL: "All",
    SINGLE_FILTERS: ["invoiceSystem", "invoiceSource", "status"],
    SINGLE_FILTERS_VAR_MAP: {
        "invoiceSystem": "selectedSystem",
        "invoiceSource": "selectedSource",
        "status": "selectedStatus",
    },

    MULTI_FILTERS: ["invoiceCountries", "custCountries", "invoiceShipFromStates", "invoiceShipToStates", "invoiceShipFromCities", "invoiceShipToCities"],
    MULTI_FILTERS_VAR_MAP: {
        "invoiceCountries": "selectedIssuingCountries",
        "custCountries": "selectedReceivingCountries",
        "invoiceShipFromStates": "selectedShipFromStates",
        "invoiceShipToStates": "selectedShipToStates",
        "invoiceShipFromCities": "selectedShipFromCities",
        "invoiceShipToCities": "selectedShipToCities",
    },

    RANGE_FILTERS: ["invoiceIdRange", "invoiceNumberRange", "invoicePeriodRange"],
    RANGE_FILTERS_VAR_MAP: {
        "invoicePeriodRange": {"start": "startPeriod", "end": "endPeriod"},
        "invoiceIdRange": {"start": "startDocNum", "end": "endDocNum"},
        "invoiceNumberRange": {"start": "startInvoiceNum", "end": "endInvoiceNum"}
    },
    RANGE_FILTER_ERROR_MAP: {
        "invoicePeriodRange": {"start": "startPeriodError", "end": "endPeriodError"},
        "invoiceIdRange": {"start": "startDocNumError", "end": "endDocNumError"},
        "invoiceNumberRange": {"start": "startInvoiceNumError", "end": "endInvoiceNumError"}
    },
    ERROR_CODE_MAP: {
        "startPeriodError": "Please enter a start period MMM-YY.",
        "endPeriodError": "Please enter an end period MMM-YY.",
        "startDocNumError": "Please enter a start id.",
        "endDocNumError": "Please enter an end id.",
        "startInvoiceNumError": "Please enter a start invoice number.",
        "endInvoiceNumError": "Please enter an end invoice number."
    },

    GENERIC_ERROR_MESSAGE: "API called failed for unknown reason ",

    REPORT: {
        FILENAME: "report.xlsx",
        DATA_URL_BASE: "data:application/vnd.ms-excel;base64,"
    },

    PDF: {
        PDF_URL_BASE: "data:application/pdf"
    },

    PERIODS: {
        "01": "JAN", "02": "FEB", "03": "MAR", "04": "APR", "05": "MAY", "06":"JUN",
        "07": "JUL", "08": "AUG", "09": "SEP", "10": "OCT", "11":"NOV", "12": "DEC"
    },

    MONTHS: {
        "JAN": "01", "FEB": "02", "MAR": "03", "APR": "04", "MAY": "05", "JUN": "06",
        "JUL": "07", "AUG": "08", "SEP": "09", "OCT": "10", "NOV": "11", "DEC": "12"
    },

    LAST_DAY: {
        "JAN": 31, "FEB":28, "MAR": 31, "APR": 30, "MAY": 31, "JUN": 30,
        "JUL": 31, "AUG": 31, "SEP": 30, "OCT":31, "NOV": 30, "DEC": 31
    }

})

export default CONSTANTS;