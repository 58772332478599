import React from 'react';
import './banner.css';

function ICIPBanner(props) {
  const { bannerText } = props;
  return (
    <React.Fragment>
    <div className="helpLinks">
        <a href="https://t.corp.amazon.com/create/templates/d898c25f-7edc-492b-be79-ca996da98c07" target="_blank">Cut a ticket</a>
        <a href="https://w.amazon.com/bin/view/FinTech_Intercompany_Transactions_invoice_portal_sop" target="_blank">Help</a>
    </div>
    <div className="ICIPBanner">
      <h1>{bannerText}</h1>
    </div>
    </React.Fragment>
  );
}

export default ICIPBanner;