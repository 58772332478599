import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import rootReducer from './reducers';

export default function configureStore(initialState) {
  const middleware = applyMiddleware(thunk, logger);
  const store = createStore(rootReducer, initialState, middleware);

  store.subscribe(() => console.log(store.getState()))
  return store;
}
