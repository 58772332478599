// Create a reducer function from a map
// of Action names to Reducer functions.

export default function createReducer(initialState, handlers = {}) {
  return function reducer(state = initialState, action) {
    if (Object.keys(handlers).includes(action.type)) {
      return handlers[action.type](state, action);
    }
    return state;
  };
}
