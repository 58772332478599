import React from 'react';
import '@amzn/awsui-components-react/index.css';
import {FormField, Input, ColumnLayout} from '@amzn/awsui-components-react';
import './DocumentIdInput.css'

class DocumentIdInput extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            documentId: "",
            documentIdStart: "",
            documentIdEnd: "",
            error: false
        };

        this.updateValue = this.updateValue.bind(this);
    }

    setErrorState() {
        this.setState({error: true})
    }

    undoErrorState() {
        this.setState({error: false})
    }

    updateValue(varName, val) {
        this.setState({[varName]: val});
    }

    render() {
        const {placeholder1,
                placeholder2,
                title,
                placeholder3,
                placeholder4,
                placeholder5
              } = this.props;
        return (
            <React.Fragment>
                <h4>{title}</h4>
                <ColumnLayout columns={2}>
                    <div className="columns" data-awsui-column-layout-root="true">
                        <div className="IdInput">
                            <FormField label={placeholder1} errorText={placeholder3}>
                                <Input onInput={e => this.props.updateRange(this.props.startVar, e.detail.value)}
                                        value={this.props.startVarVal}
                                        invalid={this.state.error}
                                        placeholder={placeholder5}
                                ></Input>
                            </FormField>
                        </div>
                        <div className="IdInput">
                            <FormField label={placeholder2} errorText={placeholder4}>
                                <Input onInput={e => this.props.updateRange(this.props.endVar, e.detail.value)}
                                        value={this.props.endVarVal}
                                        invalid={this.state.error}
                                        placeholder={placeholder5}
                                ></Input>
                            </FormField>
                        </div>
                    </div>
                </ColumnLayout>
            </React.Fragment>
        );
    }
}

export default DocumentIdInput;