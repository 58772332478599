const DATA_HANDLER = {
    getEmptyFilters() {
        const empty = {
            "invoicePeriodRange": {},
            "invoiceIdRange": {},
            "invoiceShipFromStates": [],
            "invoiceShipToStates": [],
            "invoiceShipFromCities": [],
            "invoiceShipToCities": [],
            "invoiceCountries": [],
            "custCountries": [],
            "status": "",
            "invoiceSource": "",
            "invoiceSystem": "",
            "invoiceNumberRange": {}
        };
        return empty
    },

    createInvoiceTableRows(invoices, num) {
        var invoiceRows = [];
        invoices.forEach( invoice => {
            var row = {};
            row.number = num;
            row.eInvoice = invoice.eInvoice;
            row.invoiceSystem = invoice.invoiceSystem;
            row.invoiceSource = invoice.invoiceSource;
            row.invoiceNumber = invoice.invoiceNumber;
            row.invoiceId = invoice.invoiceId;
            row.status = invoice.status;
            row.genericParty = invoice.header.genericParty;
            row.custParty = invoice.header.custParty;
            row.selfBillingStatus = invoice.selfBillingStatus;
            row.selfBillingEInvoiceEligible = invoice.selfBillingEInvoiceEligible;
            invoiceRows.push(row);
            num++;
        })
        return invoiceRows;
    }
}

export default DATA_HANDLER