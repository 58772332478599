export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const GET_COMPANY_CODES_REQUEST = 'GET_COMPANY_CODES_REQUEST';
export const GET_COMPANY_CODES_SUCCESS = 'GET_COMPANY_CODES_SUCCESS';
export const GET_COMPANY_CODES_FAILURE = 'GET_COMPANY_CODES_FAILURE';

export const GET_INVOICES_COUNTRIES_REQUEST = 'GET_INVOICES_COUNTRIES_REQUEST';
export const GET_INVOICES_COUNTRIES_SUCCESS = 'GET_INVOICES_COUNTRIES_SUCCESS';
export const GET_INVOICES_COUNTRIES_FAILURE = 'GET_INVOICES_COUNTRIES_FAILURE';

export const GENERATE_REPORT_REQUEST = 'GENERATE_REPORT_REQUEST';
export const GENERATE_REPORT_SUCCESS = 'GENERATE_REPORT_SUCCESS';
export const GENERATE_REPORT_FAILURE = 'GENERATE_REPORT_FAILURE';

export const FETCH_INVOICES_REQUEST = 'FETCH_INVOICES_REQUEST';
export const FETCH_INVOICES_SUCCESS = 'FETCH_INVOICES_SUCCESS';
export const FETCH_INVOICES_FAILURE = 'FETCH_INVOICES_FAILURE';

export const FETCH_INVOICE_DOCUMENT_REQUEST = 'FETCH_INVOICE_DOCUMENT_REQUEST';
export const FETCH_INVOICE_DOCUMENT_SUCCESS = 'FETCH_INVOICE_DOCUMENT_SUCCESS';
export const FETCH_INVOICE_DOCUMENT_FAILURE = 'FETCH_INVOICE_DOCUMENT_FAILURE';