import * as CONST from '../common/const';
import {getUsernameFromCognitoSession, getJwtTokenFromCognitoSession} from '../utils/auth';

export const loginSuccess = (auth) => {
    const session = auth.getSignInUserSession();
    const username = getUsernameFromCognitoSession(session);
    const jwtToken = getJwtTokenFromCognitoSession(session);
    return {
        type: CONST.LOGIN_SUCCESS,
        payload: {
            username,
            jwtToken,
        },
    };
};

export const loginFailure = err => ({
    type: CONST.LOGIN_FAILURE,
    payload: err,
});
