import {ensureAuthenticated} from './auth/authenticate';
import Logger from './logger';

let baseUrl = '/api'
const environment = process.env.NODE_ENV;

function authenticate() {
    return ensureAuthenticated();
}

async function callApi(method, endpoint, body = null, headers = {}) {
    const auth = await authenticate();
    const session = auth.getSignInUserSession();
    const jwtToken = session.getIdToken().getJwtToken();
    baseUrl = process.env.BUSINESS_LOGIC_API;

    const url = `${baseUrl}${endpoint}`;

    async function checkStatus(response) {
        if (response.status === 200) {
            return response;
        }

        if (response.status === 400) {
            const responseBody = await response.json();
            const error = new Error(responseBody.message);
            error.response = response;
            throw error;
        } else if (response.status === 500) {
            const error = new Error(response.message)
            error.response = response;
            throw error;
        } else {
            const error = new Error(response.message);
            error.response = response;
            throw error;
        }
    }

    function parseJSON(response) {
        return response.json();
    }

    const response = await fetch(url, {

        method: method,
        mode: 'cors',
        cache: 'no-cache',
        headers: {
            ...headers,
            Authorization: jwtToken,

        },
        redirect: 'follow',
        referrer: 'no-referrer',
        body: method === 'GET' ? null : JSON.stringify(body),
    });
    await checkStatus(response);
    const result = await parseJSON(response);

    Logger.logInfo(`HTTP ${method} ${endpoint} = ${result}`);
    return result;
}


const API = {
    async generateReport(payload) {
        const result = await callApi('POST', '/generateReport', payload);
        return result;
    },

    async fetchInvoices(payload) {
        const result = await callApi('POST', '/fetchInvoices', payload);
        return result;
    },

    async fetchInvoiceDocument(payload) {
        const result = await callApi('POST', '/fetchInvoiceDocument', payload);
        return result;
    },

    async getCompanyCodes() {
        const result = await callApi('GET', '/getCompanyCodes');
        return result;
    },

    async getInvoiceCountries() {
        const result = await callApi('GET', '/getInvoiceCountries');
        return result
    },

    async getShippingStates() {
        return await callApi('GET', '/getShippingStates');
    },

    async getShippingCities() {
        return await callApi('GET', '/getShippingCities');
    }
};

export default API;