import React from 'react';
import { SideNavigation } from '@amzn/awsui-components-react';

function Navigation() {
    const header = {text: 'Menu', href: '/'};
    const items = [
        {
            type: "link",
            text: "Search",
            href: '/'
        }
    ];

    return (
        <SideNavigation
            items={items}
            header={header}
        />
    );
}

export default Navigation;