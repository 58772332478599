import React from 'react';
import CONSTANTS from "../../../../utils/constants.js";
import DATA_HANDLER from "../../../../utils/dataHandling.js";
import '@amzn/awsui-components-react/index.css';
import {FormField, Alert, Button, Select, DatePicker, Multiselect, ColumnLayout} from '@amzn/awsui-components-react';
import CompanySelector from './CompanySelector';
import "./SearchBox.css";
import DocumentIdInput from "./DocumentIdInput";
import API from '../../../../utils/api'


const source = [{label: 'All', value: 'All'}, {label: 'VT Cost-Plus', value: 'Cost-Plus Accounting'},
    {label: 'VT MJE', value: 'AMZ Manual Journals'}, {label: 'AMZ AP', value: 'AMZ AP Recharge'},
    {label: 'IC MJE', value: "IC MJE"}, {label: 'ATP', value: "ICATP"}]

const system = [{label: 'All', value: 'All'}, {label: "Virtual Trader", value: "Virtual Trader"},
    {label: 'IC MJE', value: 'IC MJE'}]

const status = [{label: 'All', value: 'All'}, {label: 'Completed', value: 'Complete'},{label: 'In Progress', value: 'In Progress'}]

const defaults = {
    "selectedSource": source[0],
    "selectedSystem": system[0],
    "selectedStatus": status[0],
    "selectedShipFromStates": [],
    "selectedShipToStates": [],
    "selectedShipFromCities": [],
    "selectedShipToCities": [],
    "selectedIssuingCountries": [],
    "selectedReceivingCountries": [],
    "startPeriod": "",
    "endPeriod": "",
    "startDocNum": "",
    "endDocNum": "",
    "startInvoiceNum": "",
    "endInvoiceNum": "",
    "relationships": []
}

class SearchBox extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedSource: source[0],
            selectedSystem: system[0],
            selectedStatus: status[0],
            companyId: "",
            companiesTo: [],
            companiesFrom: [],
            shipFromStates: [],
            shipToStates: [],
            shipFromCities: [],
            shipToCities: [],
            issuingCountries: [],
            receivingCountries: [],
            selectedShipFromStates: [],
            selectedShipToStates: [],
            selectedShipFromCities: [],
            selectedShipToCities: [],
            selectedIssuingCountries: [],
            selectedReceivingCountries: [],
            startPeriod: "",
            endPeriod: "",
            startDocNum: "",
            endDocNum: "",
            startInvoiceNum: "",
            endInvoiceNum: "",
            relationships: [],
            errorState: false,
            errorMessage: "",
            invalidDate: false,
            statusTypeShipFromState: "pending",
            statusTypeShipToState: "pending",
            statusTypeShipFromCity: "pending",
            statusTypeShipToCity: "pending",
            statusTypeIssuingCountry: "pending",
            statusTypeReceivingCountry: "pending",
            startPeriodError: "",
            endPeriodError: "",
            startDocNumError: "",
            endDocNumError: "",
            startInvoiceNumError: "",
            endInvoiceNumError: ""
        };


        this.updateState = this.updateState.bind(this);
        this.updateRange = this.updateRange.bind(this);
        this.prepareFilters = this.prepareFilters.bind(this);
        this.updateRelationships = this.updateRelationships.bind(this);
        this.clearAllFields = this.clearAllFields.bind(this);
        this.resetErrors = this.resetErrors.bind(this);
        this.generateRelationships = this.generateRelationships.bind(this);
        this.isValidFilter = this.isValidFilter.bind(this);
    }

    componentDidMount() {
        this.setState({statusTypeIssuingCountry: "loading"});
        this.setState({statusTypeReceivingCountry: "loading"});
        API.getInvoiceCountries()
            .then(
                (result) => {
                    this.issuingCountrySetup(result.invoiceCountries);
                    this.receivingCountrySetup(result.custCountries);
                    this.setState({statusTypeIssuingCountry: "finished"});
                    this.setState({statusTypeReceivingCountry: "finished"});
                })
            .catch((e) => {
                this.setState({statusTypeIssuingCountry: "error"});
                this.setState({statusTypeReceivingCountry: "error"});
            });

        this.setState({statusTypeShipFromState: "loading"});
        this.setState({statusTypeShipToState: "loading"});
        API.getShippingStates()
            .then(
                (result) => {
                    this.shipFromStateSetup(result.invoiceShipFromStates)
                    this.shipToStateSetup(result.invoiceShipToStates);
                    this.setState({statusTypeShipFromState: "finished"});
                    this.setState({statusTypeShipToState: "finished"});
                }
            ).catch((e) => {
            this.setState({statusTypeShipFromState: "error"});
            this.setState({statusTypeShipToState: "error"});
        })

        this.setState({statusTypeShipFromCity: "loading"});
        this.setState({statusTypeShipToCity: "loading"});
        API.getShippingCities()
            .then(
                (result) => {
                    this.shipFromCitySetup(result.invoiceShipFromCities)
                    this.shipToCitySetup(result.invoiceShipToCities);
                    this.setState({statusTypeShipFromCity: "finished"});
                    this.setState({statusTypeShipToCity: "finished"});
                }
            ).catch((e) => {
            this.setState({statusTypeShipFromCity: "error"});
            this.setState({statusTypeShipToCity: "error"});
        })
    }

    shipFromStateSetup(states) {
        const shipFromStates = states.map((state, index)=> {return {label: state, id: index}})
        this.setState({shipFromStates: shipFromStates});
    }

    shipToStateSetup(states) {
        const shipToStates = states.map((state, index)=> {return {label: state, id: index}})
        this.setState({shipToStates: shipToStates});
    }

    shipFromCitySetup(cities) {
        const shipFromCities = cities.map((city, index)=> {return {label: city, id: index}})
        this.setState({shipFromCities: shipFromCities});
    }

    shipToCitySetup(cities) {
        const shipToCities = cities.map((city, index)=> {return {label: city, id: index}})
        this.setState({shipToCities: shipToCities});
    }

    issuingCountrySetup(countries) {
        var invoiceCountries = [];
        var id = 1;
        countries.sort();
        countries.forEach(country => {
            var dict = {
                "label": country,
                "id": id
            };
            invoiceCountries.push(dict);
            id++;
        });
        this.setState({issuingCountries: invoiceCountries});
    }

    receivingCountrySetup(countries) {
        var invoiceCountries = [];
        var id = 1;
        countries.sort();
        countries.forEach(country => {
            var dict = {
                "label": country,
                "id": id
            };
            invoiceCountries.push(dict);
            id++;
        });
        this.setState({receivingCountries: invoiceCountries});
    }

    // function to update state in response to changes in the search fields
    updateState(varName, val) {
        this.setState({[varName]: val});
    }

    updateRange(varName, val) {
        if (val.length > 6) {
            val = val.substring(0,6);
        }
        // if varName is start and start is same as end, update both
        if (varName.includes("start")) {
            if(this.state[varName] === this.state[varName.replace("start",'end')]) {
                // start and end must be updated at same time
                this.setState({[varName]: val.toUpperCase().substr(0,6)});
                this.setState({[varName.replace("start",'end')]: val.toUpperCase()});
            } else {
                this.setState({[varName]: val.toUpperCase()});
            }
        }
        if (varName.includes("end")) {
            this.setState({[varName]: val.toUpperCase()});
        }
    }

    clearAllFields() {
        if (this.state.errorState) {
            this.resetErrors();
        }
        for (const [key, value] of Object.entries(defaults)) {
            this.setState({[key]: value});
        }
        this.refs.companySelector.clearAllFields();
        this.setState({warningState: true});
        this.setState({warningMessage: "warning"});
    }

    updateRelationships(newRelationships) {
        this.setState({relationships: newRelationships});
    }

    resetErrors() {
        this.setState({errorMessage: ""});
        this.setState({invalidDate: false});
        this.setState({errorState: false});
        this.setState({startPeriodError: ""});
        this.setState({endPeriodError: ""});
        this.setState({startDocNumError: ""});
        this.setState({endDocNumError: ""});
        this.setState({startInvoiceNumError: ""});
        this.setState({endInvoiceNumError: ""});
    }

    generateRelationships(relationships) {
        var singleRelationships = [];
        relationships.forEach(relationship => {
            singleRelationships = singleRelationships.concat(this.crossProductParty(relationship));
        })
        return singleRelationships;
    }

    crossProductParty(relationship) {
        var singleRelationships = [];
        if (relationship.genericParty.length === 0) {
            relationship.custParty.forEach(cParty => {
                var singleRelationship = {genericParty: "", custParty: cParty};
                singleRelationships.push(singleRelationship);
            });
            return singleRelationships;
        }
        if (relationship.custParty.length === 0) {
            relationship.genericParty.forEach(gParty => {
                var singleRelationship = {genericParty: gParty, custParty: ""};
                singleRelationships.push(singleRelationship);
            });
            return singleRelationships;
        }
        relationship.genericParty.forEach(gParty => {
            relationship.custParty.forEach(cParty => {
                var singleRelationship = {genericParty: gParty, custParty: cParty};
                singleRelationships.push(singleRelationship);
            });
        });
        return singleRelationships;
    }

    isValidFilter() {
        CONSTANTS.RANGE_FILTERS.forEach(filter => {
            if (filter === "invoicePeriodRange") {
                // check if the invoice period range is entered
                if (!this.state[CONSTANTS.RANGE_FILTERS_VAR_MAP[filter].start]
                    && !this.state[CONSTANTS.RANGE_FILTERS_VAR_MAP[filter].end]) {
                    this.state[CONSTANTS.RANGE_FILTER_ERROR_MAP[filter].start] =
                        CONSTANTS.ERROR_CODE_MAP[CONSTANTS.RANGE_FILTER_ERROR_MAP[filter].start];
                    this.state[CONSTANTS.RANGE_FILTER_ERROR_MAP[filter].end] =
                        CONSTANTS.ERROR_CODE_MAP[CONSTANTS.RANGE_FILTER_ERROR_MAP[filter].end];
                    this.setState({errorState: true});
                } else if (this.state[CONSTANTS.RANGE_FILTERS_VAR_MAP[filter].start] &&
                this.state[CONSTANTS.RANGE_FILTERS_VAR_MAP[filter].end]) {
                    const start = this.state[CONSTANTS.RANGE_FILTERS_VAR_MAP[filter].start];
                    const end = this.state[CONSTANTS.RANGE_FILTERS_VAR_MAP[filter].end];
                    // check if the input is in the MON-YEAR patten
                    if (!this.periodFormatIsValid(start)) {
                        this.state[CONSTANTS.RANGE_FILTER_ERROR_MAP[filter].start] = CONSTANTS.INVALID_PERIOD_FORMAT_ERROR;
                        this.setState({errorState: true});
                    }
                    if (!this.periodFormatIsValid(end)) {
                        this.state[CONSTANTS.RANGE_FILTER_ERROR_MAP[filter].end] = CONSTANTS.INVALID_PERIOD_FORMAT_ERROR;
                        this.setState({errorState: true});
                    }
                    if (this.periodFormatIsValid(start) && this.periodFormatIsValid(end) && !this.periodRangeIsValid(start, end)) {
                        this.state[CONSTANTS.RANGE_FILTER_ERROR_MAP[filter].start] = CONSTANTS.INVALID_DATE_ERROR;
                        this.state[CONSTANTS.RANGE_FILTER_ERROR_MAP[filter].end] = CONSTANTS.INVALID_DATE_ERROR;
                        this.setState({errorState: true});
                    }
                }
            }
            // check if the start range or the end range is missing
            if (!this.state[CONSTANTS.RANGE_FILTERS_VAR_MAP[filter].start]
                && this.state[CONSTANTS.RANGE_FILTERS_VAR_MAP[filter].end]) {
                this.state[CONSTANTS.RANGE_FILTER_ERROR_MAP[filter].start] =
                    CONSTANTS.ERROR_CODE_MAP[CONSTANTS.RANGE_FILTER_ERROR_MAP[filter].start];
                this.setState({errorState: true});
            } else if (this.state[CONSTANTS.RANGE_FILTERS_VAR_MAP[filter].start]
                && !this.state[CONSTANTS.RANGE_FILTERS_VAR_MAP[filter].end]) {
                this.state[CONSTANTS.RANGE_FILTER_ERROR_MAP[filter].end] =
                    CONSTANTS.ERROR_CODE_MAP[CONSTANTS.RANGE_FILTER_ERROR_MAP[filter].end];
                this.setState({errorState: true});
            }
        });
        if (this.state.errorState) {
            return false;
        }
        return true;
    }

    periodFormatIsValid(period) {
        if (!period.includes("-")) return false;
        if (period.split("-").length != 2) return false;
        if (!(period.split("-")[0] in CONSTANTS.MONTHS)) return false;
        const yy = period.split("-")[1];
        if (yy.length != 2) return false;
        const yyNum = parseInt(yy);
        if (yyNum === NaN || yyNum === undefined) return false;
        if (yyNum>99) return false;
        return true;
    }

    periodRangeIsValid(start, end) {
        const startPeriod = {"month": CONSTANTS.MONTHS[start.split('-')[0]], "year": start.split('-')[1]};
        const endPeriod = {"month": CONSTANTS.MONTHS[end.split('-')[0]], "year": end.split('-')[1]};
        if(startPeriod.year < endPeriod.year) {
            return true;
        } else if (startPeriod.year === endPeriod.year && startPeriod.month <= endPeriod.month){
            return true;
        } else {
            return false;
        }
    }


    prepareFilters() {
        this.resetErrors();
        //single filters
        var newFilters = DATA_HANDLER.getEmptyFilters();
        CONSTANTS.SINGLE_FILTERS.forEach(filter => {
            var val = this.state[CONSTANTS.SINGLE_FILTERS_VAR_MAP[filter]].value;
            if (val === CONSTANTS.ALL) return;
            else {
                newFilters[filter] = val;
            }
        });

        CONSTANTS.MULTI_FILTERS.forEach(filter => {
            var val = this.state[CONSTANTS.MULTI_FILTERS_VAR_MAP[filter]];
            var val2 = [];
            val.forEach(item => {
                val2.push(item.label)
            });
            if (val.length === 0) return;
            else {
                newFilters[filter] = val2;
            }
        });

        CONSTANTS.RANGE_FILTERS.forEach(filter => {
            var start = this.state[CONSTANTS.RANGE_FILTERS_VAR_MAP[filter].start];
            var end = this.state[CONSTANTS.RANGE_FILTERS_VAR_MAP[filter].end];
            var rangeVals = {};
            if (start === "" && end === "") return;
            rangeVals.start = start;
            rangeVals.end = end;
            newFilters[filter] = rangeVals;
        });

        if (this.state.errorState) return;
        var singleRelationships = this.generateRelationships(this.state.relationships.slice());
        newFilters.companyRelationships = singleRelationships;
        if (this.isValidFilter(newFilters)) {
            console.log("Start to call retrive invoices");
            this.props.retrieveInvoices(newFilters);
        }
    }

    render() {
        const {
            selectedSource,
            selectedSystem,
            selectedStatus,
            selectedShipFromStates,
            selectedShipToStates,
            selectedShipFromCities,
            selectedShipToCities,
            selectedIssuingCountries,
            selectedReceivingCountries
        } = this.state;

        return (
            <React.Fragment>
                <div className="awsui-util-container">
                    <div className="awsui-util-container-header">
                        <h2>Search</h2>
                    </div>
                    <ColumnLayout columns={3}>
                        <div data-awsui-column-layout-root="true">
                            <FormField label={"Source:"}>
                                <Select
                                    options={source}
                                    selectedOption={selectedSource}
                                    onChange={e => this.updateState("selectedSource", e.detail.selectedOption)}
                                />
                            </FormField>
                            <FormField label={"System:"}>
                                <Select
                                    options={system}
                                    selectedOption={selectedSystem}
                                    onChange={e => this.updateState("selectedSystem", e.detail.selectedOption)}
                                />
                            </FormField>
                            <FormField label={"Document Status:"}>
                                <Select
                                    options={status}
                                    selectedOption={selectedStatus}
                                    onChange={e => this.updateState("selectedStatus", e.detail.selectedOption)}
                                />
                            </FormField>
                        </div>
                    </ColumnLayout>
                    <ColumnLayout columns={2}>
                        <div data-awsui-column-layout-root="true">
                            <div className="issuingCountries">
                                <FormField label={"Issuing Countries:"}>
                                    <Multiselect
                                        id="multiselect"
                                        options={this.state.issuingCountries}
                                        checkboxes={true}
                                        loadingText="Retrieving Countries"
                                        errorText="Error retrieving countries"
                                        statusType={this.state.statusTypeIssuingCountry}
                                        selectedOptions={selectedIssuingCountries}
                                        onChange={e => this.updateState("selectedIssuingCountries", e.detail.selectedOptions)}
                                    >
                                    </Multiselect>
                                </FormField>
                            </div>
                            <div className="receivingCountries">
                                <FormField label={"Receiving Countries:"}>
                                    <Multiselect
                                        id="multiselect"
                                        options={this.state.receivingCountries}
                                        checkboxes={true}
                                        loadingText="Retrieving Countries"
                                        errorText="Error retrieving countries"
                                        statusType={this.state.statusTypeReceivingCountry}
                                        selectedOptions={selectedReceivingCountries}
                                        onChange={e => this.updateState("selectedReceivingCountries", e.detail.selectedOptions)}
                                    >
                                    </Multiselect>
                                </FormField>
                            </div>
                        </div>
                    </ColumnLayout>
                    <br/>
                    <br/>
                    <ColumnLayout columns={2}>
                        <div data-awsui-column-layout-root="true">
                            <div className="dropdown">
                                <FormField label={"Ship From States: "}>
                                    <Multiselect
                                        id="multiselect"
                                        options={this.state.shipFromStates}
                                        checkboxes={true}
                                        loadingText="Retrieving ship from states"
                                        errorText="Error retrieving ship from states"
                                        statusType={this.state.statusTypeShipFromState}
                                        selectedOptions={selectedShipFromStates}
                                        onChange={e => this.updateState("selectedShipFromStates", e.detail.selectedOptions)}
                                    >
                                    </Multiselect>
                                </FormField>
                            </div>
                            <div className="dropdown">
                                <FormField label={"Ship To States: "}>
                                    <Multiselect
                                        id="multiselect"
                                        options={this.state.shipToStates}
                                        checkboxes={true}
                                        loadingText="Retrieving ship to states"
                                        errorText="Error retrieving ship to states"
                                        statusType={this.state.statusTypeShipToState}
                                        selectedOptions={selectedShipToStates}
                                        onChange={e => this.updateState("selectedShipToStates", e.detail.selectedOptions)}
                                    >
                                    </Multiselect>
                                </FormField>
                            </div>
                        </div>
                    </ColumnLayout>
                    <br/>
                    <br/>
                    <ColumnLayout columns={2}>
                        <div data-awsui-column-layout-root="true">
                            <div className="dropdown">
                                <FormField label={"Ship From Cities: "}>
                                    <Multiselect
                                        id="multiselect"
                                        options={this.state.shipFromCities}
                                        checkboxes={true}
                                        loadingText="Retrieving ship from cities"
                                        errorText="Error retrieving ship from cities"
                                        statusType={this.state.statusTypeShipFromCity}
                                        selectedOptions={selectedShipFromCities}
                                        onChange={e => this.updateState("selectedShipFromCities", e.detail.selectedOptions)}
                                    >
                                    </Multiselect>
                                </FormField>
                            </div>
                            <div className="dropdown">
                                <FormField label={"Ship To Cities: "}>
                                    <Multiselect
                                        id="multiselect"
                                        options={this.state.shipToCities}
                                        checkboxes={true}
                                        loadingText="Retrieving ship to cities"
                                        errorText="Error retrieving ship to cities"
                                        statusType={this.state.statusTypeShipToCity}
                                        selectedOptions={selectedShipToCities}
                                        onChange={e => this.updateState("selectedShipToCities", e.detail.selectedOptions)}
                                    >
                                    </Multiselect>
                                </FormField>
                            </div>
                        </div>
                    </ColumnLayout>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <div>
                        <CompanySelector options={this.props.companyIds}
                                         relationships={this.state.relationships}
                                         updateRelationships={this.updateRelationships}
                                         ref="companySelector"
                        />
                    </div>
                    <div>
                        <DocumentIdInput title="Invoice ID Range:"
                                         placeholder1="Start of invoice ID:"
                                         placeholder2="End of invoice ID:"
                                         placeholder3={this.state.startDocNumError}
                                         placeholder4={this.state.endDocNumError}
                                         updateRange={this.updateState}
                                         startVar="startDocNum"
                                         endVar="endDocNum"
                                         startVarVal={this.state.startDocNum}
                                         endVarVal={this.state.endDocNum}
                                         ref="invoiceId"
                        />
                        <br/>
                        <DocumentIdInput title="Source Invoice Number Range:"
                                         placeholder1="Start of invoice number:"
                                         placeholder2="End of invoice number:"
                                         placeholder3={this.state.startInvoiceNumError}
                                         placeholder4={this.state.endInvoiceNumError}
                                         updateRange={this.updateState}
                                         startVar="startInvoiceNum"
                                         endVar="endInvoiceNum"
                                         startVarVal={this.state.startInvoiceNum}
                                         endVarVal={this.state.endInvoiceNum}
                                         ref="invoiceNumber"
                        />
                        <br/>
                        <DocumentIdInput title="Invoice Period Range:"
                                         placeholder1="Start of invoice period:"
                                         placeholder2="End of invoice period:"
                                         placeholder3={this.state.startPeriodError}
                                         placeholder4={this.state.endPeriodError}
                                         placeholder5="MMM-YY"
                                         updateRange={this.updateRange}
                                         startVar="startPeriod"
                                         endVar="endPeriod"
                                         startVarVal={this.state.startPeriod}
                                         endVarVal={this.state.endPeriod}
                        />
                    </div>

                    <div>
                        <Button variant="primary" onClick={() => {this.prepareFilters()}} disabled={this.props.isLoadingInvoices}>
                            Search
                        </Button>

                        <Button variant="normal" onClick={() => this.clearAllFields()}>
                            Clear All Fields
                        </Button>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default SearchBox;