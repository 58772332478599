import React from 'react';
import SearchBox from './components/SearchBox/SearchBox';
import ResultsTable from './components/ResultsTable'
import ICIPBanner from '../../common/banner/banner'
import DATA_HANDLER from "../../utils/dataHandling.js";
import CONSTANTS from '../../utils/constants.js';
import API from '../../utils/api'


export class HomePage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            invoiceTableData: [],
            invoiceAllData: [],
            isLoadingInvoices: false,
            numRows: 1,
            lastEvaluatedKey: "start"
        }
        this.retrieveInvoices = this.retrieveInvoices.bind(this);
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    getPeriods(startPeriod, endPeriod) {
        const start = {"month": parseInt(CONSTANTS.MONTHS[startPeriod.split('-')[0]])
            , "year": parseInt(startPeriod.split('-')[1])};
        const end = {"month": parseInt(CONSTANTS.MONTHS[endPeriod.split('-')[0]])
            , "year": parseInt(endPeriod.split('-')[1])};
        var periods = [];

        for (let i = start.year; i <= end.year; i++) {
            const endMonth = i != end.year ? 11 : end.month - 1;
            const startMonth = i === start.year ? start.month - 1 : 0;
            for (var j = startMonth; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
                var month = j + 1;
                var displayMonth = month < 10 ? '0' + month : month;
                periods.push([CONSTANTS.PERIODS[displayMonth], i].join('-'));
            }
        }
        return periods;
    }

    async retrieveInvoices(filters) {
        this.setState({numRows: 1});
        this.setState({invoiceTableData: []});
        this.setState({invoiceAllData: []});
        this.setState({isLoadingInvoices: true});
        const startPeriod = filters.invoicePeriodRange.start;
        const endPeriod = filters.invoicePeriodRange.end;
        const periods = this.getPeriods(startPeriod, endPeriod);
        for (var i = 0; i < periods.length ; i ++) {
            filters.period = periods[i];
            while (this.state.lastEvaluatedKey != null) {
                console.log(this.state.lastEvaluatedKey);
                if (this.state.lastEvaluatedKey != "start") filters.lastEvaluatedKey = this.state.lastEvaluatedKey;
                await API.fetchInvoices(filters)
                    .then((data) => {
                        this.setState({lastEvaluatedKey: data.lastEvaluatedKey});
                        this.setState({invoiceTableData: this.state.invoiceTableData.concat(DATA_HANDLER.createInvoiceTableRows(data.invoices, this.state.numRows))});
                        this.setState({invoiceAllData: this.state.invoiceAllData.concat(data.invoices)});
                        this.setState({numRows: this.state.numRows + data.count});
                    }).catch(e => {
                        this.setState({lastEvaluatedKey: null});
                        this.refs.resultsTable.setState({"noDataText": CONSTANTS.noDataText});
                    })
                if (this.state.lastEvaluatedKey != null) await this.sleep(1000);
            }
            this.setState({lastEvaluatedKey: "start"});
            filters.lastEvaluatedKey = "";
        }
        this.setState({isLoadingInvoices: false});
    }

    render() {
        return (
            <React.Fragment>
                <ICIPBanner bannerText={"Intercompany Invoice Portal"}/>
                <SearchBox isLoadingInvoices={this.state.isLoadingInvoices} 
                           retrieveInvoices={this.retrieveInvoices}
                />
                <br/>
                <ResultsTable data={this.state.invoiceTableData}
                              isLoadingInvoices={this.state.isLoadingInvoices}
                              allData={this.state.invoiceAllData}
                              ref="resultsTable"
                />
            </React.Fragment>
        );
    }
}

export default HomePage;