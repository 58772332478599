import React from 'react';
import '@amzn/awsui-components-react/index.css';
import {FormField, ColumnLayout, Button, Multiselect} from '@amzn/awsui-components-react';
import "./SearchBox.css";
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import './CompanySelector.css';
import API from '../../../../utils/api'


const defaults = {
    selectedGenericParties: [],
    selectedCustParties: [],
    relationships: [],
    checked:[],
    allSelected: false,
    selected: [],
    relationshipStrings: []
}

class CompanySelector extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            genericParties: [],
            custParties: [],
            selectedGenericParties: [],
            selectedCustParties: [],
            relationships: [],
            checked:[],
            allSelected: false,
            selected: [],
            statusType: "pending",
            relationshipStrings: []
        };
        this.updateState = this.updateState.bind(this);
        this.addRelationship = this.addRelationship.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.selectAll = this.selectAll.bind(this);
        this.companiesSetup = this.companiesSetup.bind(this);
        this.clearAllFields = this.clearAllFields.bind(this);
        this.createString = this.createString.bind(this);
    }

    componentDidMount() {

        this.setState({statusType: "loading"});
        API.getCompanyCodes()
            .then(
                (result) => {
                    this.companiesSetup(result);
                    this.setState({statusType: "finished"});
                })
            .catch((e) => {
                this.setState({statusType: "error"});
            });


    }

    clearAllFields() {
        for (const[key, value] of Object.entries(defaults)) {
            this.setState({[key]: value})
        }
    }

    companiesSetup(companies) {
        var genericParties = [];
        var custParties = [];
        var id = 1;
        companies.genericParty.sort();
        companies.custParty.sort();
        companies.genericParty.forEach( company => {
            var dict = {
                "label": company,
                "id": id
            }
            genericParties.push(dict);
            id++;
        })
        id = 1;
        companies.custParty.forEach( company => {
            var dict = {
                "label": company,
                "id": id
                }
            custParties.push(dict);
            id++;
        })
        this.setState({genericParties: genericParties});
        this.setState({custParties: custParties});
    }

    updateState(varName, val) {
        this.setState({[varName]: val})
    }

    addRelationship() {
        var companiesFrom = [];
        var companiesTo = [];
        var newChecked = this.state.checked.slice();

        if (this.state.selectedCustParties.length === 0 && this.state.selectedGenericParties.length === 0) return;

        newChecked.push(false);
        this.setState(({checked: newChecked}));
        if (this.state.selectedGenericParties.length !== 0) {
            this.state.selectedGenericParties.forEach( company => {
                companiesFrom.push(company.label);
            })
        }
        if (this.state.selectedCustParties.length !== 0) {
            this.state.selectedCustParties.forEach( company => {
                companiesTo.push(company.label);
            })
        }

        var genericPartyString = this.createString(companiesFrom);
        var custPartyString = this.createString(companiesTo);
        var relationshipString = {
            "genericParty": genericPartyString,
            "custParty": custPartyString
        }

        var relationship = {
            "genericParty": companiesFrom,
            "custParty": companiesTo
        };
        var newRelationships = this.state.relationships.slice();
        var newRelationShipStrings = this.state.relationshipStrings.slice();
        newRelationships.push(relationship);
        newRelationShipStrings.push(relationshipString);
        this.setState(({relationships: newRelationships}));
        this.setState({selectedGenericParties: []});
        this.setState({selectedCustParties: []});
        this.setState({relationshipStrings: newRelationShipStrings})
        this.props.updateRelationships(newRelationships);
    }

    removeRelationship() {
        var newRelationships = [];
        var newRelationshipStrings = [];
        var newChecked = [];
        if (this.state.allSelected) {
            this.setState({allSelected: false});
        }
        else {
            for (var i = 0; i < this.state.checked.length; i++) {
                if (!this.state.checked[i]) {
                    newRelationships.push(this.state.relationships[i]);
                    newRelationshipStrings.push(this.state.relationshipStrings[i]);
                    newChecked.push(false);
                }
            }
        }
        this.setState({relationships: newRelationships});
        this.setState({relationshipStrings: newRelationshipStrings})
        this.setState({checked: newChecked});
        this.props.updateRelationships(newRelationships);
    }

    createString(companies) {
        var companiesString = "";
        for (var i = 0; i < companies.length; i++) {
            companiesString += companies[i];
            if (i < companies.length - 1) {
                companiesString += ", "
            }
        }
        return companiesString;
    }

    handleClick(rowIndex) {
        var newChecked = this.state.checked.slice();
        newChecked[rowIndex] = !newChecked[rowIndex];
        this.setState({checked: newChecked});
    }

    selectAll() {
        const select = !this.state.allSelected;
        var newChecked = this.state.checked.slice();
        for (var i = 0; i < newChecked.length; i++) {
            newChecked[i] = select;
        }
        this.setState({allSelected: select});
        this.setState({checked: newChecked});
    }

    static defaultProps = {
            options: []
        }

    render() {
        const columns = [{
                            Header: <input type="checkbox"
                            checked={this.state.allSelected}
                            onChange={()=>this.selectAll()}
                            />,
                            Cell: row=> (<input
                                type="checkbox"
                                checked={this.state.checked[row.index]}
                                onChange={()=>this.handleClick(row.index)}
                                />
                            ),
                            sortable: false,
                            width:50
                           },
                          {
                            Header: "Companies From",
                            accessor: "genericParty"
                          },
                          {
                            Header: "Companies To",
                            accessor: "custParty"
                          }
                        ]


        return (
            <React.Fragment>
                <div className="companySelector">
                    <ColumnLayout columns={2}>
                        <div data-awsui-column-layout-root="true">
                            <div className="awsui-util-container awsui-util-pv-l-no-gutters">
                             <h3>Create Relationship</h3>
                             <div className="companies">
                                <FormField label="Companies From:">
                                    <Multiselect
                                        id="multiselect"
                                        options={this.state.genericParties}
                                        checkboxes={true}
                                        selectedOptions={this.state.selectedGenericParties}
                                        loadingText="Retrieving Company Codes"
                                        errorText="Error retrieving companies"
                                        statusType={this.state.statusType}
                                        filteringType="auto"
                                        onChange={e => this.updateState("selectedGenericParties",
                                            e.detail.selectedOptions)}
                                    >
                                    </Multiselect>
                                </FormField>
                                </div>
                                <div className="companies">
                                <FormField label="Companies To:">
                                    <Multiselect
                                        id="multiselect"
                                        options={this.state.custParties}
                                        checkboxes={true}
                                        selectedOptions={this.state.selectedCustParties}
                                        loadingText="Retrieving Company Codes"
                                        errorText="Error retrieving companies"
                                        statusType={this.state.statusType}
                                        filteringType="auto"
                                        onChange={e => this.updateState("selectedCustParties",
                                            e.detail.selectedOptions)}
                                    >
                                    </Multiselect>
                                </FormField>
                                </div>
                                <Button id="button2"
                                    onClick = {e=>this.addRelationship()}
                                >

                                Create Relationship</Button>

                            </div>
                            <div className="awsui-util-container awsui-util">
                                <h3>Current Relationships</h3>
                                <ReactTable
                                    class="relationships"
                                    data={this.state.relationshipStrings}
                                    columns={columns}
                                    minRows={8}
                                    showPagination={false}
                                    noDataText="No Relationships Added"
                                >
                                </ReactTable>
                                <div>
                                    <Button
                                        onClick={()=>this.removeRelationship()}
                                    >Remove Relationship</Button>
                                </div>
                            </div>
                        </div>
                    </ColumnLayout>
                </div>
            </React.Fragment>

            )
         }
    }

export default CompanySelector;