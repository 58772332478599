import React from 'react'
import ReactDOM from 'react-dom'
import {BrowserRouter} from 'react-router-dom'
import {AppLayout} from '@amzn/awsui-components-react';
import Navigation from './common/navigation'
import ICIPApp from './ICIPApp'
import ICIPAppRestrict from "./ICIPAppRestrict";
import './index.css'
import {ensureAuthenticated} from "./utils/auth/authenticate";
import {loginSuccess, loginFailure} from './actions/auth';
import {Provider} from 'react-redux';
import configureStore from './store';

const store = configureStore({});

function startICIPApp() {
    ensureAuthenticated()
        .then((auth) => {
            store.dispatch(loginSuccess(auth));
            ReactDOM.render(

                <Provider store={store}>
                    <BrowserRouter>
                        <div className="awsui">
                            <AppLayout
                                content={<ICIPApp/>}
                                navigation={<Navigation/>}
                            />
                        </div>
                    </BrowserRouter>,
                </Provider>,
                document.getElementById('root')
            );
        })
        .catch((e) => {
            console.log('ensureAuthenticated threw an exception: ', e);
            store.dispatch(loginFailure(e));
            ReactDOM.render(
                <BrowserRouter>
                    <ICIPAppRestrict/>
                </BrowserRouter>,
                document.getElementById('root'),
            );
        });
}

startICIPApp();